import { useTheme } from '@chakra-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Slider from '../Slider'
import NewCustomerReviewCard from './CustomerTestimonialComponent'
import { getCustomerTestimonials } from '../../../freeTrial/constants/customerReviews'

import { ResponsiveType } from '../Slider/types'

const responsive: ResponsiveType = {
  desktop3000: {
    breakpoint: { max: 3000, min: 2600 },
    items: 5,
    centerMode: true,
    showArrows: true,
  },
  desktop2600: {
    breakpoint: { max: 2600, min: 1800 },
    items: 4,
    centerMode: true,
    showArrows: true,
  },
  desktop1800: {
    breakpoint: { max: 1800, min: 1300 },
    items: 3,
    centerMode: true,
    showArrows: true,
  },
  desktop1300: {
    breakpoint: { max: 1300, min: 920 },
    items: 2,
    centerMode: true,
    showArrows: true,
  },
  tablet: {
    breakpoint: { max: 920, min: 768 },
    items: 2,
    centerMode: true,
    showArrows: true,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    showArrows: false,
    showDots: true,
  },
}

const CustomerTestimonials = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  const transition = `transform ${theme.durations.default} ${theme.easings.default}`

  return (
    <Slider
      responsive={responsive}
      keyBoardControl
      infinite
      roundedArrows
      transition={transition}
      arrowPosition={8}
    >
      {getCustomerTestimonials(t).map((testimonial) => {
        return (
          <NewCustomerReviewCard
            key={testimonial.name}
            testimonial={testimonial}
          />
        )
      })}
    </Slider>
  )
}

export default CustomerTestimonials
